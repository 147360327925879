"use client";

import React from "react";

import { Box, Flex, Space, Stack, Text } from "@mantine/core";

import ReactPlayer from "react-player";
import FallbackBox from "../FallbackBox/FallbackBox";

interface ParticipantVideoMessageProps {
    sentAt: Date;
    blobUrl: string | undefined;
}

export default function ParticipantVideoMessageBubble({ sentAt, blobUrl }: ParticipantVideoMessageProps): Readonly<React.ReactElement<ParticipantVideoMessageProps>> {
    const [error, setError] = React.useState<React.SyntheticEvent<HTMLImageElement, Event> | null>(null);

    React.useEffect(() => setError(null), []);

    return (
        <Flex
            w={'70%'}
            h={'70%'} mt={'md'}
            direction={'row'}
            justify={'start'}
            align={'start'}
            wrap={'nowrap'}
        >
            <Flex
                w={'70%'}
                h={'70%'}
                direction={'row'}
                justify={'start'}
                align={'end'}
                wrap={'nowrap'}

            >
                <Stack gap={0}>
                    <Box
                        w={'100%'} h={'100%'}
                        style={{
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderRadius: "12px"
                        }}
                        maw={620}
                    >
                        {error ? (
                            <FallbackBox message="ไม่สามารถดึงข้อมูลวีดีโอได้" />
                        ) : (
                            <ReactPlayer
                                url={blobUrl}
                                loop controls volume={1}
                                width={"auto"} height={"auto"}
                                onError={setError}
                            />
                        )}
                    </Box>
                </Stack>
                <Space ml={12} />
                <Text c={'#404040'}>{sentAt.toLocaleTimeString()}</Text>
            </Flex>
        </Flex>
    );
}
