"use client";

import React from "react";

import { Box, Flex, Stack, Text } from "@mantine/core";

import ReactPlayer from "react-player";
import FallbackBox from "../FallbackBox/FallbackBox";

interface AdminVideoMessageBubbleProps {
    sentAt: Date;
    senderName: string | undefined;
    blobUrl: string | undefined;
}

export default function AdminVideoMessageBubble({sentAt, senderName, blobUrl}: AdminVideoMessageBubbleProps): Readonly<React.ReactElement<AdminVideoMessageBubbleProps>> {
    const [error, setError] = React.useState<React.SyntheticEvent<HTMLImageElement, Event> | null>(null);

    React.useEffect(() => setError(null), []);

    return (
        <Flex
            w={'100%'}
            h={'auto'} mt={'md'}
            direction={'row'}
            justify={'end'}
            align={'end'}
            wrap={'nowrap'}
        >
            <Flex
                w={'100%'}
                h={'auto'}
                direction={'row'}
                justify={'end'}
                align={'end'}
                wrap={'nowrap'}

            >
                <Stack c={'#404040'} gap={0} align={'end'}>
                    <Text>({senderName})</Text>
                    <Text>{sentAt.toLocaleTimeString()}</Text>
                </Stack>
                <Stack ml={16} gap={0}>
                    <Box
                        w={'auto'} h={'auto'}
                        p={10}
                        style={{
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderRadius: "12px"
                        }}
                    >
                        {error ? (
                            <FallbackBox message="ไม่สามารถดึงข้อมูลวีดีโอได้" />
                        ) : (
                            <ReactPlayer
                                url={blobUrl}
                                loop controls volume={1}
                                width={"100%"} height={"100%"}
                                onError={setError}
                            />
                        )}
                    </Box>
                </Stack>
            </Flex>
        </Flex>
    );
}
