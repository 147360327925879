import { persistentAtom } from "@nanostores/persistent";

import { User } from "@vorderseite/types/user";

export const $userProfile = persistentAtom<User>("user", {
    id: "",
    created_at: "",
    updated_at: "",
    username: "",
    role: "helpdesk",
    status: "active",
}, {
    encode: JSON.stringify,
    decode: JSON.parse
});
