"use client";

import React from "react";

import dropzoneStyles from "./../../styles/Dropzone.module.css";

import { Badge, Box, Center, Text } from "@mantine/core";
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";

import {
    LoaderCentered,
    AdminImageMessageBubble,
    AdminTextMessageBubble,
    ParticipantImageMessageBubble,
    ParticipantTextMessageBubble,
    ParticipantVideoMessageBubble,
    AdminVideoMessageBubble,
} from "..";

import type { MessageHistory } from "@vorderseite/types/chat";

import InfiniteScroll from "react-infinite-scroll-component";
import { RoomNotifyMessage } from "@vorderseite/types/room";

interface ChatProps {
    roomID: string;

    dataLength: number;
    histories?: MessageHistory[] | undefined;

    loadingHistories: boolean;

    next: () => void;
    hasMore: boolean;

    onDrop: (fwp: FileWithPath[]) => void;

    innerRef: any;
}

export default function Chat(
    props: ChatProps
): Readonly<React.ReactElement<ChatProps> | null> {
    if (!props.histories) return null;

    const groupedHistories = props.histories.reduce(
        (accumulator: MessageHistory[][], history) => {
            const lastGroup = accumulator[accumulator.length - 1];
            const sentAt = new Date(history.created_at).toDateString();

            if (
                lastGroup &&
                lastGroup[0] &&
                new Date(lastGroup[0].created_at).toDateString() === sentAt
            ) {
                lastGroup.push(history);
            } else {
                accumulator.push([history]);
            }

            return accumulator;
        },
        []
    );

    return (
        <>
            {props.loadingHistories ? (
                <LoaderCentered />
            ) : (
                <Box
                    pos={"relative"}
                    component={"div"}
                    w={"100%"}
                    h={"100%"}
                    py={12}
                    style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        scrollBehavior: "smooth",
                        overflow: "auto",
                    }}
                    id="scrollableDiv"
                    ref={props.innerRef}
                    bg={"#f8fafc"}
                >
                    <InfiniteScroll
                        dataLength={props.dataLength}
                        next={props.next}
                        hasMore={props.hasMore}
                        scrollableTarget={"scrollableDiv"}
                        style={{
                            padding: "14px",
                            display: "flex",
                            flexDirection: "column-reverse",
                        }}
                        inverse={true}
                        hasChildren
                        loader={
                            <Box my={"lg"}>
                                <LoaderCentered size={"lg"} />
                            </Box>
                        }
                    >
                        {groupedHistories.map((group, index) => {
                            const sentAt =
                                group.length > 0
                                    ? new Date(group[0].created_at)
                                    : null;

                            return (
                                <React.Fragment key={index}>
                                    {group.map((history, historyIndex) => {
                                        const sentAt = new Date(
                                            history.created_at
                                        );

                                        return (
                                            <React.Fragment key={historyIndex}>
                                                <Dropzone
                                                    className={`${dropzoneStyles.root} ${dropzoneStyles.inner}`}
                                                    enablePointerEvents={true}
                                                    accept={IMAGE_MIME_TYPE}
                                                    activateOnClick={false}
                                                    onDrop={(fwp) =>
                                                        props.onDrop(fwp)
                                                    }
                                                >
                                                    {history.sender_type ===
                                                            "participant" &&
                                                            (history.message_type ===
                                                                "sticker" ||
                                                                history.message_type ===
                                                                    "image") && (
                                                                <ParticipantImageMessageBubble
                                                                    key={
                                                                        historyIndex
                                                                    }
                                                                    sentAt={
                                                                        sentAt
                                                                    }
                                                                    blobURL={String(
                                                                        history.blob_url
                                                                    )}
                                                                />
                                                            )}
                                                        {history.sender_type ===
                                                            "participant" &&
                                                            history.message_type ===
                                                                "video" && (
                                                                <ParticipantVideoMessageBubble
                                                                    key={
                                                                        historyIndex
                                                                    }
                                                                    sentAt={
                                                                        sentAt
                                                                    }
                                                                    blobUrl={
                                                                        history.blob_url
                                                                    }
                                                                />
                                                            )}
                                                        {history.sender_type ===
                                                            "participant" &&
                                                            history.message_type ===
                                                                "text" && (
                                                                <ParticipantTextMessageBubble
                                                                    key={
                                                                        historyIndex
                                                                    }
                                                                    sentAt={
                                                                        sentAt
                                                                    }
                                                                    message_text={
                                                                        history.message_text
                                                                    }
                                                                />
                                                            )}
                                                        {history.sender_type ===
                                                            "bot" &&
                                                            history.message_type ===
                                                                "text" && (
                                                                <AdminTextMessageBubble
                                                                    key={
                                                                        historyIndex
                                                                    }
                                                                    sentAt={
                                                                        sentAt
                                                                    }
                                                                    messageText={
                                                                        history.message_text
                                                                    }
                                                                    senderName={
                                                                        history.sender_name
                                                                    }
                                                                />
                                                            )}
                                                        {history.sender_type ===
                                                            "bot" &&
                                                            (history.message_type ===
                                                                "sticker" ||
                                                                history.message_type ===
                                                                    "image") && (
                                                                <AdminImageMessageBubble
                                                                    key={
                                                                        historyIndex
                                                                    }
                                                                    sentAt={
                                                                        sentAt
                                                                    }
                                                                    senderName={
                                                                        history.sender_name
                                                                    }
                                                                    blobURL={
                                                                        history.blob_url
                                                                    }
                                                                />
                                                            )}
                                                        {history.sender_type ===
                                                            "bot" &&
                                                            history.message_type ===
                                                                "video" && (
                                                                <AdminVideoMessageBubble
                                                                    key={
                                                                        historyIndex
                                                                    }
                                                                    sentAt={
                                                                        sentAt
                                                                    }
                                                                    senderName={
                                                                        history.sender_name
                                                                    }
                                                                    blobUrl={
                                                                        history.blob_url
                                                                    }
                                                                />
                                                            )}
                                                </Dropzone>
                                            </React.Fragment>
                                        );
                                    })}
                                    <Center my="lg" bg={"#f8fafc"}>
                                        <Badge
                                            color="#ededed"
                                            size="xl"
                                            c="dark"
                                        >
                                            วันที่{" "}
                                            {sentAt?.toLocaleDateString(
                                                "th-TH",
                                                {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                }
                                            )}
                                        </Badge>
                                    </Center>
                                </React.Fragment>
                            );
                        })}
                    </InfiniteScroll>
                </Box>
            )}
        </>
    );
}
