"use client";

import React from "react";

import Link from "next/link";

import { Flex, Stack, Text, Title } from "@mantine/core";

import Tab from "../Tab/Tab";
import MagicBorder from "../MagicBorder/MagicBorder";

import { Navigate } from "@vorderseite/types/navigate";

import { notoSerif, seedSans } from "@vorderseite/styles/fonts";

import { IconDots} from "@tabler/icons-react";
import { $tabSelectorState } from "@vorderseite/store/states";

interface SidebarProps {
    navigates: Navigate[];
    openModal: () => void;
}

// Deprecated Sidebar Componnent
export default function Sidebar({ navigates, openModal }: SidebarProps): Readonly<React.ReactElement<SidebarProps>> {
    return (
        <Stack
            component={'div'}
            w={'100%'} h={'100%'}
            pt={18} pl={26} pr={16}
            maw={360} gap={'lg'}
            style={{
                borderRight: '1px solid #ececec'
            }}
        >
            <Title
                className={notoSerif.className}
                fz={52}
            >
                Vorderseite
            </Title>
            <Flex
                w={'100%'}
                direction={'row'}
                justify={'start'}
                wrap={'nowrap'}
                mb={24}
            >
                <Stack
                    justify={'center'}
                    gap={0}
                >
                    <Text
                        className={seedSans.className}
                        fw={600}
                        fz={20}
                    >
                        กล่องข้อความ
                    </Text>
                    <Text
                        className={notoSerif.className}
                        fw={500}
                        fz={20}
                        c={'#7f7f7f'}
                    >
                        yongyuth@gmail.com
                    </Text>
                </Stack>
                <Flex
                    w={'100%'}
                    justify={'end'}
                    align={'center'}
                >
                    <MagicBorder
                        width={32}
                        height={32}
                        hover
                        onClick={openModal}
                    >
                        <IconDots color={'#7f7f7f'} />
                    </MagicBorder>
                </Flex>
            </Flex>
            {navigates.map((navigate) => {
                return (
                    <Link key={navigate.id} href={navigate.pathname} onClick={() => $tabSelectorState.set(navigate.pathname)}>
                        <Tab
                            label={navigate.label}
                            pathname={navigate.pathname}
                            icon={navigate.icon}
                        />
                    </Link>
                );
            })}
        </Stack>
    );
}
