import axiosRequest from "@vorderseite/utils/axios";

import type { MessageResponse } from "@vorderseite/types/http_interaction";
import type { BotCreateRequest, BotResponse, BotManyResponse, BotUpdateRequest } from "@vorderseite/types/bot";

export const botService = {
    Create(body: BotCreateRequest): Promise<MessageResponse> {
        return axiosRequest.post("/api/v1/bots", body);
    },

    Find(id: string): Promise<BotResponse> {
        return axiosRequest.get(`/api/v1/bots?id=${id}`);
    },

    FindMany(enabledMessages: boolean): Promise<BotManyResponse> {
        if (enabledMessages) {
            return axiosRequest.get(`/api/v1/bots?enabled_total_messages=true`);
        }

        return axiosRequest.get(`/api/v1/bots`);
    },

    Update(id: string, body: BotUpdateRequest): Promise<MessageResponse> {
        return axiosRequest.put(`/api/v1/bots/${id}`, body);
    },

    Delete(id: string): Promise<MessageResponse> {
        return axiosRequest.delete(`/api/v1/bots/${id}`);
    }
};
