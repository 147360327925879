"use client";

/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { Box, Flex, Stack, Text } from '@mantine/core';

import Image from "react-image-enlarger";
import FallbackBox from "../FallbackBox/FallbackBox";

interface AdminImageMessageBubbleProps {
    sentAt: Date;
    senderName?: string;
    blobURL?: string;
}

export default function AdminImageMessageBubble({ sentAt, senderName, blobURL }: AdminImageMessageBubbleProps): Readonly<React.ReactElement<AdminImageMessageBubbleProps> | null> {
    const [zoomed, setZoomed] = React.useState<boolean>(false);
    const [error, setError] = React.useState<React.SyntheticEvent<HTMLImageElement, Event> | null>(null);

    React.useEffect(() => setError(null), []);

    return (
        <Flex
            w={'100%'}
            h={'auto'} mt={'md'}
            direction={'row'}
            justify={'end'}
            align={'end'}
            wrap={'nowrap'}
        >
            <Flex
                w={'100%'}
                h={'auto'}
                direction={'row'}
                justify={'end'}
                align={'end'}
                wrap={'nowrap'}

            >
                <Stack c={'#404040'} gap={0} align={'end'}>
                    <Text>({senderName})</Text>
                    <Text>{sentAt.toLocaleTimeString()}</Text>
                </Stack>
                <Stack ml={16} gap={0}>
                    <Box
                        w={'auto'} h={'auto'}
                        p={10}
                        style={{
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            borderRadius: "12px"
                        }}
                        maw={620}
                    >
                        {error ? (
                            <FallbackBox message="ไม่สามารถดึงข้อมูลรูปภาพได้" />
                        ) : (
                            <Image
                                zoomed={zoomed}
                                src={blobURL as string}
                                onClick={() => setZoomed(true)}
                                onRequestClose={() => setZoomed(false)}
                                onError={setError}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    overflow: 'hidden',
                                    borderRadius: "12px"
                                }}
                            />
                        )}
                    </Box>
                </Stack>
            </Flex>
        </Flex>
    );
}
