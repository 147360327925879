import React from "react";

import { Box, MantineSpacing, StyleProp } from "@mantine/core";

interface ContainerProps {
    px?: StyleProp<MantineSpacing>
    maw?: StyleProp<React.CSSProperties['maxWidth']>;
}

export default function Container(
    {
        children,
        px = 'sm',
        maw = '80rem'
    }: ContainerProps & React.PropsWithChildren
): Readonly<React.ReactElement<ContainerProps & React.PropsWithChildren>> {
    return (
        <Box
            w={'100%'}
            h={'auto'}
            mx={'auto'}
            px={px}
            maw={maw}
        >
            {children}
        </Box>
    );
}
