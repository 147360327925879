import axios from "axios";
import type { AxiosError, AxiosResponse } from "axios"

const axiosRequest = axios.create({
    baseURL: `https://${process.env.NEXT_PUBLIC_API_ENDPOINT}` || "http://localhost:8080",
});

axiosRequest.interceptors.request.use((config: any) => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosRequest.interceptors.response.use(
    (response) => response.data,
    (error: AxiosError) => {
        const response = error.response as AxiosResponse;

        if (response?.data.message) {
            return Promise.reject(response.data);
        }

        return Promise.reject({
            message: "ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้"
        })
    }
);

export default axiosRequest;
