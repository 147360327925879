"use client";

import React from "react";

import { usePathname } from "next/navigation";

import { Flex, Text } from "@mantine/core";
import { useStore } from "@nanostores/react";
import { $tabSelectorState } from "@vorderseite/store/states";

interface TabProps {
    label: string;
    pathname: string
    icon: JSX.Element;
}

export default function Tab(props: TabProps): Readonly<React.ReactElement> {
    const currentRoute = usePathname();
    const [isHovering, setIsHovering] = React.useState<boolean>(false);

    const selectedTabState = useStore($tabSelectorState);

    return (
        <>
            <Flex
                w={'100%'}
                my={4}
                justify={'start'}
                align={'center'}
                wrap={'nowrap'}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <Flex
                    w={'100%'} gap={'xs'}
                    c={
                        (selectedTabState == props.pathname && !isHovering) ?
                            '#000000' : (isHovering) ? '#3c3c3c' : '#666666'
                    }
                    style={{
                        transitionProperty: 'all',
                        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                        transitionDuration: '150ms'
                    }}
                >
                    {props.icon}
                    <Text fz={20}>
                        {props.label}
                    </Text>
                </Flex>
            </Flex>
        </>
    );
}
