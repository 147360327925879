"use client";

import React from "react";

import Image from "next/image";

import { Box, Button, Flex, Grid, Group, Modal, Stack, Text } from "@mantine/core";

import { motion } from 'framer-motion';

import { EnabledText } from "@vorderseite/components";
import { Bot } from "@vorderseite/types/bot";
import { IconChartCandle, IconListDetails, IconTrash } from "@tabler/icons-react";
import { botService } from "@vorderseite/services/bot_services";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";

import BotImage from "./../../../public/bot.png";
import { $selectedBotProfiles } from "@vorderseite/store/bots";

interface BotCardProps {
    bot: Bot;
}

export default function BotCard({ bot }: BotCardProps): Readonly<React.ReactElement<BotCardProps>> {
    const [opened, { open, close }] = useDisclosure(false);

    const [isHovering, setIsHovering] = React.useState<boolean>(false);
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

    const DeleteBot = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        event.preventDefault();
        void setIsDeleting(true);

        try {
            const response = await botService.Delete(id);
            void close();

            void notifications.show({
                withBorder: true,
                autoClose: 4096,
                title: <Text fw={700} size={'lg'}>{response.message}</Text>,
                message: <Text size={'lg'}>เราได้ดำเนินการตามที่คุณต้องการเสร็จสิ้นแล้ว</Text>,
                color: 'green',
            });
            void setIsDeleting(false);
            void $selectedBotProfiles.set([]);

            if (typeof window !== 'undefined') {
                void window.location.reload();
            }

            return;

        } catch ({ message }: any) {
            void setIsDeleting(false);
            return notifications.show({
                withBorder: true,
                autoClose: 4096,
                title: (
                    <Text fw={700} size={"lg"}>
                        ไม่สามารถลบบอทดังกล่าวได้
                    </Text>
                ),
                message: <Text size={"lg"}>{message}</Text>,
                color: "red",
            });
        }
    };

    const UpdateStatusBot = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        void event.preventDefault();
        void setIsUpdating(true);

        await botService.Update(
            id, {bot_status: bot.bot_status === "enabled" ? "disabled" : "enabled"}
        ).then((response) => {
            void notifications.show({
                withBorder: true,
                autoClose: 3072,
                title: <Text fw={700} size={'lg'}>{response.message}</Text>,
                message: <Text size={'lg'}>เราได้ดำเนินการตามที่คุณต้องการเสร็จสิ้นแล้ว</Text>,
                color: 'green',
            });

            void setIsUpdating(false);
            if (typeof window !== 'undefined') {
                void window.location.reload();
            }

            return;
        })
        .catch(({ message }: any) => {
            void setIsUpdating(false);
            return notifications.show({
                withBorder: true,
                autoClose: 4096,
                title: (
                    <Text fw={700} size={"lg"}>
                        ไม่สามารถอัปเดตบอทดังกล่าวได้
                    </Text>
                ),
                message: <Text size={"lg"}>{message}</Text>,
                color: "red",
            });
        });
    };

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                withCloseButton={false}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3
                }}
                radius={8}
                centered
            >
                <Stack gap={'xl'}>
                    <Stack gap={0}>
                        <Group gap={'xs'}>
                            <IconListDetails />
                            <Text fw={600} fz={24}>รายละเอียดเพิ่มเติม</Text>
                        </Group>
                        <Text fz={18} c={'#666666'}>รายละเอียดเกี่ยวกับบอท {bot.display_name}</Text>
                    </Stack>
                    <Stack gap={'md'}>
                        <Button
                            variant={'filled'}
                            color={'gray.4'}
                            size={'lg'}
                            radius={'md'}
                            c={'dark'}
                            onClick={() => void close()}
                        >
                            <Text fz={18} fw={700}>ยกเลิก</Text>
                        </Button>
                        {bot.bot_status === "enabled" ? (
                            <Button
                                variant={'filled'}
                                color={'red.7'}
                                size={'lg'}
                                radius={'md'}
                                c={'white'}
                                onClick={(event) => UpdateStatusBot(event, bot.bot_id)}
                                loading={isUpdating}
                            >
                                <Group gap={4}>
                                    <IconChartCandle size={28} />
                                    <Text fz={18} fw={700}>ปิดการใช้งาน</Text>
                                </Group>
                            </Button>
                        ) : (
                            <Button
                                variant={'filled'}
                                color={'green.6'}
                                size={'lg'}
                                radius={'md'}
                                c={'white'}
                                onClick={(event) => UpdateStatusBot(event, bot.bot_id)}
                                loading={isUpdating}
                            >
                                <Group gap={4}>
                                    <IconChartCandle size={28} />
                                    <Text fz={18} fw={700}>เปิดการใช้งาน</Text>
                                </Group>
                            </Button>
                        )}
                        <Button
                            variant={'filled'}
                            color={'red.7'}
                            size={'lg'}
                            radius={'md'}
                            c={'white'}
                            onClick={(event) => DeleteBot(event, bot.bot_id)}
                            loading={isDeleting}
                        >
                            <Group gap={4}>
                                <IconTrash size={28} />
                                <Text fz={18} fw={700}>ลบบัญชีบอท</Text>
                            </Group>
                        </Button>
                    </Stack>
                </Stack>
            </Modal>

            <Grid.Col
                key={bot.bot_id} span={{
                    base: 12,
                    xs: 8,
                    sm: 6,
                    md: 4,
                    lg: 3,
                    xl: 2
                }}
                onMouseEnter={() => void setIsHovering(true)}
                onMouseLeave={() => void setIsHovering(false)}
            >
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.4,
                        delay: 0.0,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    whileHover={{ scale: 0.98 }}
                    style={{ cursor: "pointer" }}
                >
                    <Box
                        bg={'#ececec'}
                        w={'100%'}
                        h={{
                            base: 300,
                            sm: 320,
                            md: 340,
                            lg: 360,
                            xl: 380
                        }}
                        pos={'relative'}
                        style={{
                            borderRadius: '12px',
                            overflow: 'hidden'
                        }}
                    >
                        <Image
                            src={bot.picture_url ? bot.picture_url : BotImage}
                            alt={`${bot.display_name}'s picture`}
                            fill
                            sizes="auto"
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                objectFit: 'cover',
                                zIndex: 0
                            }}
                        />
                        <Box
                            p={10}
                            pos={'absolute'}
                            bottom={0}
                            left={0}
                            w={'100%'}
                            h={"auto"}
                            style={{
                                background: 'rgba(44, 44, 44, 0.6)',
                            }}
                        >
                            {isHovering ? (
                                <Flex
                                    w={'100%'}
                                    h={'100%'}
                                    justify={'flex-end'}
                                    align={"end"}
                                >
                                    <Button
                                        w={'100%'}
                                        variant={'filled'}
                                        color={'gray.6'}
                                        size={'sm'}
                                        radius={'md'}
                                        c={'white'}
                                        onClick={() => open()}
                                    >
                                        <Group gap={4}>
                                            <IconListDetails size={18} />
                                            <Text fz={14} fw={700}>รายละเอียดเพิ่มเติม</Text>
                                        </Group>
                                    </Button>
                                </Flex>
                            ) : (
                                <Stack
                                    h={'100%'}
                                    justify={"flex-end"}
                                >
                                    <Stack gap={0}>
                                        <Text
                                            w={'100%'}
                                            fz={{
                                                lg: 16,
                                                xl: 20
                                            }}
                                            fw={600}
                                            c={'#ffffff'}
                                            truncate={'end'}
                                        >
                                            {bot.display_name}
                                        </Text>
                                        <Text
                                            w={'100%'}
                                            fz={{
                                                lg: 12,
                                                xl: 16,
                                            }}
                                            c={'#e8e8e8'}
                                            truncate={'end'}
                                        >
                                            {bot.basic_id}
                                        </Text>
                                    </Stack>
                                    <EnabledText
                                        statuses={bot.bot_status}
                                        fontSize={{
                                            lg: 14,
                                            xl: 18
                                        }}
                                    />
                                </Stack>
                            )}
                        </Box>
                    </Box>
                </motion.div>
            </Grid.Col>
        </>
    );
}
