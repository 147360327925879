import type { UserRoles } from "@vorderseite/types/user";

const restrictedRoutes = ["/bots", "/accounts"];

export function restricted(path: string, role: UserRoles): boolean {
    if (role === "helpdesk") {
        for (let route in restrictedRoutes) {
            if (path == restrictedRoutes[route]) {
                return true
            }
        }
    }

    return false
}
