import React from "react";

import Image from "next/image";

import { EnabledText } from "..";

import { Box, Flex, Group, Stack, Text } from "@mantine/core";

import { Bot } from "@vorderseite/types/bot";

import LineBrandingImage from "./../../../public/line_branding.png";

import BotImage from "./../../../public/bot.png";

interface BotProfileShowcaseProps {
    bot: Bot;
    hovered?: boolean;
}

export default function BotProfileShowcase({ bot, hovered = true }: BotProfileShowcaseProps): Readonly<React.ReactElement<BotProfileShowcaseProps>> {
    const [isHovering, setIsHovering] = React.useState<boolean>(false);

    return (
        <Flex
            w={'100%'}
            h={'auto'}
            p={16}
            direction={'row'}
            justify={'center'}
            align={'center'}
            wrap={'nowrap'}
            style={{
                transitionProperty: 'all',
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                transitionDuration: '150ms',
                borderRadius: '12px',
                cursor: 'pointer'
            }}
            bg={isHovering && hovered ? '#f7f7f7' : ''}
            onMouseEnter={() => void setIsHovering(true)}
            onMouseLeave={() => void setIsHovering(false)}
        >
            <Group gap={'xs'} w={'100%'}>
                <Box
                    w={64}
                    h={64}
                    pos={'relative'}
                >
                    <Box
                        w={64}
                        h={64}
                        pos={'relative'}
                        style={{
                            borderRadius: '50%',
                            overflow: 'hidden'
                        }}
                        bg={'#ececec'}

                    >
                        <Image
                            src={bot.picture_url ? bot.picture_url : BotImage}
                            alt={`${bot.display_name}'s picture`}
                            fill
                            sizes="auto"
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                objectFit: 'cover',
                                overflow: 'hidden',
                                zIndex: 10
                            }}
                        />
                    </Box>
                    <Box
                        pos={'absolute'}
                        bottom={0}
                        left={0}
                        w={24}
                        h={24}
                        style={{ zIndex: 20 }}
                    >
                        <Image
                            src={LineBrandingImage}
                            alt={"line branding"}
                            fill
                            sizes="auto"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 4,
                                position: 'absolute',
                                objectFit: 'cover',
                                overflow: 'hidden',
                                zIndex: 10
                            }}
                        />
                    </Box>
                </Box>
                <Stack gap={0}>
                    <Text w={'200px'} fz={22} truncate={'end'} c={'#3a3a3a'}>{bot.display_name}</Text>
                    <EnabledText statuses={bot.bot_status} fontSize={18} />
                </Stack>
            </Group>
        </Flex>
    );
}
