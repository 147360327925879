import React from "react";

import { Input } from "@mantine/core";

import type { MantineRadius, MantineSize } from "@mantine/core";

import { IconSearch } from "@tabler/icons-react";

interface InputBoxProps {
    width?: string | undefined;
    label?: string | undefined;
    icon?: JSX.Element | undefined;
    size?: MantineSize | (string & {}) | undefined;
    radius?: MantineRadius | undefined;

    value?: string | number | readonly string[] | undefined;

    dispatch: React.Dispatch<React.SetStateAction<any>>;
    keydown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function InputBox({
    width = 'auto',
    label,
    icon = <IconSearch />,
    size = 'lg',
    radius = 8,

    value,

    dispatch,
    keydown = (_) => {}
}: InputBoxProps): Readonly<React.ReactElement<InputBoxProps>> {
    return (
        <Input
            w={width}
            size={size}
            radius={radius}
            variant={'filled'}
            leftSection={icon}
            defaultValue={value}
            placeholder={label !== undefined ? label : 'ค้นหา'}
            onChange={(event) => void dispatch(event.target.value)}
            onKeyDown={(event) => void keydown(event)}
        />
    )
}
