import React from "react";

import Link from "next/link";

import { DefaultMantineColor, Group, StyleProp, Text, UnstyledButton, Kbd } from "@mantine/core";

import { IconQuestionMark } from "@tabler/icons-react";

interface CommandMenuTabProps {
    pathname?: string | null | undefined;
    text?: string | null | undefined;
    icon?: JSX.Element | null | undefined;
    textColor: StyleProp<DefaultMantineColor>;
    bgColor: StyleProp<DefaultMantineColor>;
    bgColorHover: StyleProp<DefaultMantineColor>;
}

export default function CommandMenuTab(props: CommandMenuTabProps): Readonly<React.ReactElement<CommandMenuTabProps>> {
    const [isHover, setIsHover] = React.useState<boolean>(false);

    return (
        <UnstyledButton
            component={Link}
            href={{pathname: props.pathname === undefined ? "/" : props.pathname}}
            w={'100%'}
            c={props.bgColor}
            onMouseEnter={() => void setIsHover(true)}
            onMouseLeave={() => void setIsHover(false)}
        >
            <Group
                p={10}
                bg={isHover ? props.bgColorHover : props.bgColor}
                style={{
                    borderRadius: '8px',
                    transitionProperty: 'all',
                    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    transitionDuration: '150ms',
                }}
            >
                {props.icon === undefined ? <IconQuestionMark color={'#000000'} size={26} /> : props.icon}
                <Text c={props.textColor} fz={20}>{props.text === undefined ? "ไม่พบข้อความ" : props.text}</Text>
            </Group>
        </UnstyledButton>
    );
}
