import { Room } from "@vorderseite/types/room";
import { atom } from "nanostores";

export const $selectedRoom = atom<Room | undefined>({
    room_id: "",
    participant: {
        id: "",
        display_name: "",
        picture_url: ""
    },
    bot: {
        id: "",
        display_name: "",
    },
    name_remark: "",
    note_message: "",
    latest_sender_name: "",
    latest_reply_at: ""
});
