import { Box } from "@mantine/core";
import React from "react";

interface CounterBubbleProps {
    totalMessages: number | undefined;

    limitTotalMessages?: number | undefined;
    limitLabelTotalMessages?: string | undefined;
}

export default function CounterBubble({ totalMessages, limitTotalMessages = 99, limitLabelTotalMessages = "99+" }: CounterBubbleProps): Readonly<React.ReactElement<CounterBubbleProps>> {
    return (
        <>
            {(totalMessages && totalMessages > 0) && (
                <Box
                    component={'div'}
                    style={{
                        padding: '0px 6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'nowrap',
                        borderRadius: '8px',
                        color: '#ffffff',
                        fontWeight: '600',
                        backgroundColor: '#f2b105'
                    }}
                >
                    {totalMessages > limitTotalMessages ? limitLabelTotalMessages : totalMessages}
                </Box>
            )}
        </>
    );
}
