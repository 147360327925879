"use client";

import React from "react";

import { usePathname, useSearchParams } from "next/navigation";

import { NavigationProgress, nprogress } from "@mantine/nprogress";

export default function RouterTransition(): Readonly<React.ReactElement> {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    React.useEffect(() => {
        setTimeout(() => nprogress.complete(), 450);

        return () => {
            nprogress.start();
        }
    }, [pathname, searchParams]);

    return <NavigationProgress size={6} color={'#f2b105'} />;
}
