export function getTimePassed(startDate: Date, endDate: Date) {
    let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());

    let daysPassed = Math.floor(timeDiff / (1000 * 3600 * 24));
    let remainingTime = timeDiff - (daysPassed * 1000 * 3600 * 24);
    let hoursPassed = Math.floor(remainingTime / (1000 * 3600));

    remainingTime = remainingTime - (hoursPassed * 1000 * 3600);

    let minutesPassed = Math.floor(remainingTime / (1000 * 60));

    return {
        days: daysPassed,
        hours: hoursPassed,
        minutes: minutesPassed
    };
}

export function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
