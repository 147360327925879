import React from "react";

import { Box, Flex, Space, Stack, Text } from "@mantine/core";

interface ParticipantTextMessageBubbleProps {
    sentAt: Date;
    message_text?: string | undefined;
}

export default function ParticipantTextMessageBubble({ sentAt, message_text }: ParticipantTextMessageBubbleProps): Readonly<React.ReactElement<ParticipantTextMessageBubbleProps>> {
    const processedMessage = (): React.ReactNode => {
        if (!message_text) {
            return null;
        }

        if (!message_text.includes("\r")) {
            return message_text;
        }

        const processedMessage = message_text.replaceAll("\r", "<br />");
        return <div dangerouslySetInnerHTML={{ __html: processedMessage }} />
    };

    return (
        <Flex
            w={'100%'}
            h={'auto'} mt={'md'}
            direction={'row'}
            justify={'start'}
            align={'start'}
            wrap={'nowrap'}
        >
            <Flex
                w={'100%'}
                h={'auto'}
                direction={'row'}
                justify={'start'}
                align={'end'}
                wrap={'nowrap'}
            >
                <Stack gap={0}>
                    <Box
                        w={'auto'} h={'auto'}
                        p={10} bg={'#ededed'}
                        style={{
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                        }}
                    >
                        <Text fz={20}>{processedMessage()}</Text>
                    </Box>
                </Stack>
                <Space ml={12} />
                <Text c={'#404040'}>{sentAt.toLocaleTimeString()}</Text>
            </Flex>
        </Flex>
    );
}
