"use client";

import React from "react";

import { Loader, Flex, type MantineSize } from '@mantine/core';

interface LoaderCenteredProps {
    size?: number | (string & {}) | MantineSize | undefined
}

export default function LoaderCentered({ size = "xl" }: LoaderCenteredProps): Readonly<React.ReactElement<LoaderCenteredProps>> {
    return (
        <Flex
            w={'100%'}
            h={'100%'}
            justify={'center'}
            align={'center'}
            wrap={'nowrap'}
        >
            <Loader color="#f2b105" size={size} type="bars" />
        </Flex>
    );
}
