"use client";

import React from "react";

import Image from "next/image";

import { $selectedBotProfiles } from "@vorderseite/store/bots";
import { CounterBubble, EnabledText } from "..";

import { useStore } from '@nanostores/react';

import { Box, Flex, Group, Stack, Text } from "@mantine/core";

import { Bot } from "@vorderseite/types/bot";

import BotImage from "./../../../public/bot.png";

interface BotProfileTabProps {
    bot: Bot;
    hasMessages?: boolean;
    hovered?: boolean;
}

export default function BotProfileTab({ bot, hasMessages = false, hovered = true }: BotProfileTabProps): Readonly<React.ReactElement<BotProfileTabProps>> {
    const [isHovering, setIsHovering] = React.useState<boolean>(false);

    const selectedBotProfiles = useStore($selectedBotProfiles);
    const isBotInclude = selectedBotProfiles.some((profile) => profile.bot_id === bot.bot_id)

    const totalUsers = React.useMemo((): React.ReactNode | null => {
        if (bot.total_users) {
            if (bot.total_users > 999) {
                return <b>(999+)</b>;
            }

            return <b>({bot.total_users})</b>;
        }

        return null;
    }, [bot]);

    return (
        <Flex
            w={'100%'}
            h={'auto'}
            p={16}
            direction={'row'}
            justify={'center'}
            align={'center'}
            wrap={'nowrap'}
            bg={
                isBotInclude && hovered ?
                    '#ffefc5' : !isBotInclude && isHovering && hovered
                        ? '#f7f7f7' : ''
            }
            style={{
                transitionProperty: 'all',
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                transitionDuration: '150ms',
                borderRadius: '12px',
                cursor: 'pointer'
            }}
            onMouseEnter={() => void setIsHovering(true)}
            onMouseLeave={() => void setIsHovering(false)}
        >
            <Group gap={'xs'} w={'100%'} wrap="nowrap">
                <Box
                    w={64}
                    h={64}
                    pos={'relative'}
                    style={{
                        borderRadius: '50%',
                        overflow: 'hidden'
                    }}
                    bg={'#ececec'}
                    miw={64}
                    mih={64}
                >
                    <Image
                        src={bot.picture_url ? bot.picture_url : BotImage}
                        alt={`${bot.display_name}'s picture`}
                        fill
                        sizes="auto"
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            objectFit: 'cover',
                            overflow: 'hidden'
                        }}
                    />
                </Box>
                <Stack gap={0}>
                    <Text w={'200px'} fz={18} truncate={'end'} c={'#3a3a3a'}>{bot.display_name} {totalUsers}</Text>
                    <Text w={'200px'} fz={14} truncate={'end'} c={'#666666'}>{bot.basic_id}</Text>
                    <EnabledText statuses={bot.bot_status} fontSize={14} />
                </Stack>
            </Group>
            {(hasMessages && bot.total_unseen_messages !== 0) &&
                <CounterBubble
                    totalMessages={bot.total_unseen_messages}
                />
            }
        </Flex>
    );
}
