import { Flex, Text } from "@mantine/core";
import React from "react";

interface FallbackBoxProps {
    message?: string | undefined;
}

export default function FallbackBox({ message }: FallbackBoxProps): Readonly<React.ReactElement> {
    return (
        <Flex
            w={420}
            h={420}
            direction={"column"}
            justify={"center"}
            align={"center"}
            bg={"#ececec"}
            style={{
                borderRadius: "12px",
            }}
        >
            <Text fw={"bold"} fz={"xl"} c={"gray.7"}>
                {message ? message : "ไม่สามารถดึงข้อมูลได้"}
            </Text>
            <Text fz={"lg"} c={"gray.6"}>อาจจะเกิดจากข้อมูลหมดอายุหรือเกิดข้อผิดพลาด</Text>
        </Flex>
    );
}
