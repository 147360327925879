"use client";

import React from "react";

import { UnstyledButton } from "@mantine/core";

interface MagicBorderProps {
    width: string | number;
    height: string | number;
    hover?: boolean
    onClick?: () => void;
}

export default function MagicBorder({ children, width, height, hover, onClick }: React.PropsWithChildren & MagicBorderProps): Readonly<React.ReactElement<React.PropsWithChildren & MagicBorderProps>> {
    const [isHovering, setIsHovering] = React.useState<boolean>(false);

    return (
        <UnstyledButton
            component={'button'}
            style={{
                width: width,
                height: height,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'nowrap',
                border: '1px solid #d0d0d0',
                borderRadius: '8px',
                transitionProperty: 'all',
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                transitionDuration: '150ms'
            }}
            bg={(hover && isHovering) ? '#f0f0f0' : '#ffffff'}
            onMouseEnter={() => void setIsHovering(true)}
            onMouseLeave={() => void setIsHovering(false)}
            onClick={onClick}
        >
            {children}
        </UnstyledButton>
    );
}
